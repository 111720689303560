// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
// const context = require.context("controllers", true, /_controller\.js$/)
// application.load(definitionsFromContext(context))
import debounce from "lodash.debounce";

import {
  Dropdown,
  Modal,
  Popover,
  Toggle,
  Slideover,
} from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

import Flatpickr from "stimulus-flatpickr";
application.register("flatpickr", Flatpickr);

import NestedForm from "./nested_form_controller";
application.register("nested-form", NestedForm);

import DropdownSelect from "./dropdown_select_controller";
application.register("dropdown-select", DropdownSelect);

import Invoices from "./invoices_controller";
application.register("invoices", Invoices);

import InvoiceMain from "./invoice_main_controller";
application.register("invoice-main", InvoiceMain);

import Autosave from "stimulus-rails-autosave";
application.register("autosave", Autosave);

// import Tabs from './tabs'
// application.register('tabs', Tabs)

application.register("dropdown", Dropdown);

import SelectForm from "./select_form_controller";
application.register("select-form", SelectForm);

import Toastr from "./toastr_controller";
application.register("toastr", Toastr);

import Datatable from "./datatable_controller";
application.register("datatable", Datatable);

import { Tabs } from "tailwindcss-stimulus-components";
application.register("tabs", Tabs);

import Clipboard from "./clipboard_controller";
application.register("clipboard", Clipboard);

import Quantites from "./quantities_controller";
application.register("quantities", Quantites);

import Sidebar from "./sidebar_controller";
application.register("sidebar", Sidebar);
