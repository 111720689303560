import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    console.log("sidebar connected");
    // Initialize menu state
    this.isOpen = false;
  }

  toggleMenu() {
    console.log("toggleMenu");
    this.isOpen = !this.isOpen;

    const menu = document.querySelector('[role="dialog"]');
    if (!menu) return;

    if (this.isOpen) {
      menu.classList.remove("hidden");
      // Add transition classes
      requestAnimationFrame(() => {
        menu.querySelector(".bg-gray-900\\/80").classList.add("opacity-100");
        menu.querySelector(".transform").classList.remove("-translate-x-full");
      });
    } else {
      // Remove transition classes
      menu.querySelector(".bg-gray-900\\/80").classList.remove("opacity-100");
      menu.querySelector(".transform").classList.add("-translate-x-full");

      // Hide after transition
      setTimeout(() => {
        menu.classList.add("hidden");
      }, 300);
    }
  }
}
